import React from 'react';
import Rout from './Routes';
import {Suspense} from 'react';


// comm
// var hours = 1; // to clear the localStorage after 1 hour
//                // (if someone want to clear after 8hrs simply change hours=8)
// var now = new Date().getTime();
// var setupTime = localStorage.getItem('setupTime');
// if (setupTime == null) {
//     alert("please log in")
// } else {
//     if(now-setupTime > hours*60*60*1000) {
//         localStorage.clear()
// 		alert("You have been logged out due to session end.\n Please login again.")
// 		window.location.href = '/';
//     }
// }


function App() {
  return (
	<Suspense fallback={<div>loading ....</div>}>
		<Rout />
	</Suspense>
  );
}
export default App;

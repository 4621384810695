import React, { Suspense, lazy, useEffect } from 'react';
import './index.css';
import 'swiper/css';
import 'swiper/css/pagination';
// import './libraries/bootstrap-5.0.2-dist/js/bootstrap.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


const HomePage = lazy(() => import(/* webpackPreload: true */ './pages/HomePage'));
const Layout = lazy(() => import(/* webpackPreload: true */ './pages/Layout'));
const Navbar = lazy(() => import(/* webpackPreload: true */ './components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Invoice = lazy(() => import('./pages/Invoice'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Cart = lazy(() => import('./pages/Cart'));
const Favourities = lazy(() => import('./pages/Favourities'));
const CheckOut = lazy(() => import('./pages/CheckOut'));
// const Payment = lazy(() => import('./pages/Payment'));
const Profile = lazy(() => import('./pages/Profile'));
const Orders = lazy(() => import('./pages/Orders'));
const Cancellations = lazy(() => import('./pages/Cancellations'));
const Returns = lazy(() => import('./pages/Returns'));
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'));
const Redirect = lazy(() => import('./pages/Redirect'));
const StoreHome = lazy(() => import('./pages/StoreHome'));
const Store = lazy(() => import('./pages/Store'));
const ProductDetails = lazy(() => import('./pages/ProductDetails'));
const ReturnRequest = lazy(() => import('./pages/ReturnRequest'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const ReturnPolicy = lazy(() => import('./pages/ReturnPolicy'));
const TermsOfUsage = lazy(() => import('./pages/TermsOfUsage'));
const OurLocations = lazy(() => import('./pages/OurLocations'));

const BootCss = () => {
	const link = document.createElement('link');
    link.href = './libraries/bootstrap-5.0.2-dist/css/bootstrap.min.css';
    document.head.appendChild(link);
};

function Rout () {
	useEffect(() => {
		BootCss();
		
	}, []);
	return (
		<Suspense
		  fallback={
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',backgroundColor:"#1d1d1d" }}>
			  <img style={{ width: '50vmin', height: '50vmin' }} src="./logo.png" alt="loading" />
			</div>
		  }
		>
		<Router>
		  <Navbar />
		  <Routes>
			<Route path="*" element={<PageNotFound />} />
			<Route path="/" element={<Layout />}>
			  <Route index element={<HomePage />} />
			  <Route path="login" element={<Login />} />
			  <Route path="signup" element={<SignUp />} />
			  <Route path="invoice" element={<Invoice />} />
			  <Route path="aboutUs" element={<AboutUs />} />
			  <Route path="cart" element={<Cart />} />
			  <Route path="pageNotFound" element={<PageNotFound />} />
			  <Route path="favourities" element={<Favourities />} />
			  <Route path="checkout" element={<CheckOut />} />
			  {/* <Route path="payment" element={<Payment />} /> */}
			  <Route path="profile" element={<Profile />} />
			  <Route path="orders" element={<Orders />} />
			  <Route path="cancellables" element={<Cancellations />} />
			  <Route path="returns" element={<Returns />} />
			  <Route path="forgetPassword" element={<ForgetPassword />} />
			  <Route path="redirect" element={<Redirect />} />
			  <Route path="store" element={<StoreHome />} />
			  <Route path="store/:category" element={<Store />} />
			  <Route path="product/:productID" element={<ProductDetails />} />
			  <Route path="returnRequest" element={<ReturnRequest />} />
			  <Route path="privacyPolicy" element={<PrivacyPolicy />} />
			  <Route path="returnPolicy" element={<ReturnPolicy />} />
			  <Route path="TermsOfUsage" element={<TermsOfUsage />} />
			  <Route path="ourStores" element={<OurLocations />} />
			</Route>
		  </Routes>
		  <Footer />
		</Router>
		</Suspense>
	);
};

export default Rout;
